






import Vue from 'vue';
import StaffPreAlertList from '@/components/staffprealertmanager/StaffPreAlertList.vue';
export default Vue.extend({
  name: 'StaffPreAlertListPage',
  components: {
    StaffPreAlertList,
  },
});
