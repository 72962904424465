




















































































































































































































import auth from '@/mixins/auth';
import { PreAlert } from '@/models/preAlert';
import ErrorAlert from '../common/ErrorAlert.vue';

import { ErrorManager } from '@/models/error';
import { searchItems } from '@/utils/functions';
import { mapActions, mapState } from 'vuex';
import { Account } from '@/models/account';
import { omit } from 'lodash';
import PreAlertInformationDialog from './PreAlertInformationDialog.vue';
import PreAlertForm from '../dashboard/PreAlertForm.vue';
import StaffPreAlertManagerMobile from './StaffPreAlertManagerMobile.vue';

export default auth.extend({
  components: {
    ErrorAlert,
    PreAlertInformationDialog,
    PreAlertForm,
    StaffPreAlertManagerMobile,
  },
  name: 'StaffPreAlertManager',
  data() {
    return {
      loading: false,
      dialog: false,
      deletePreAlertDialog: false,
      snackbar: false,

      errorMessage: '',
      detailErrorMessage: '',
      search: '',
      snackbarEmail: '',

      chosenPreAlert: new PreAlert(),
      preAlertTypeActiveView: { value: -1, text: 'All' } as number | any,

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Tracking Number',
          value: 'tracking_number',
        },

        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Shipper',
          value: 'shipper',
        },
        {
          text: 'Courier',
          value: 'courier',
        },
        {
          text: 'Invoices',
          value: 'invoices',
        },
        {
          text: 'Fullfilled',
          value: 'fullfilled',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState('preAlert', ['preAlert']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    screenWidth() {
      return window.innerWidth > 600;
    },

    preAlertTypeViews(): any {
      const allViewsOption = [
        { text: 'All', value: -1 },
        { text: 'Fullfilled', value: true },
        { text: 'Unfullfilled', value: false },
      ];

      return allViewsOption;
    },

    filterPreAlertType(): PreAlert[] {
      let preAlertListWithFullName = this.preAlert.map((preAlert: any) => {
        preAlert.name = `${(preAlert.user as Account).first_name} ${
          (preAlert.user as Account).last_name
        }`;
        return preAlert;
      });

      preAlertListWithFullName = searchItems<PreAlert>(
        preAlertListWithFullName,
        this.search
      );

      if (this.preAlertTypeActiveView.value != -1) {
        return preAlertListWithFullName.filter(
          (preAlert: PreAlert) =>
            preAlert.fullfilled == this.preAlertTypeActiveView.value
        );
      } else {
        return preAlertListWithFullName;
      }
    },
  },

  watch: {
    search: {
      handler(value) {
        //if mobile view
        if (!this.screenWidth) {
          this.filterPreAlertType;
        }
      },
    },
  },

  methods: {
    ...mapActions({
      deletePreAlert: 'preAlert/deletePreAlert',
      fetchPreAlerts: 'preAlert/fetchPreAlerts',
      updatePreAlert: 'preAlert/updatePreAlert',
    }),

    openDialog(item = undefined) {
      if (item) {
        (this as any).$refs.prealertform.open(item, true, true);
      } else {
        (this as any).$refs.prealertform.open(item, undefined, true);
      }
    },

    selectedPreAlert(item: PreAlert) {
      this.chosenPreAlert = item;
      this.deletePreAlertDialog = true;
    },

    clearAllErrors() {
      this.detailErrorMessage = '';
      this.errorMessage = '';
    },

    openPreAlertDetailDialog(preAlert: PreAlert) {
      (this.$refs.preAlertInformationDialog as any).open(preAlert);
    },

    updatePreAlertFullfilled(preAlert: PreAlert) {
      this.clearAllErrors();
      this.loading = true;

      this.updatePreAlert(omit(preAlert, ['user']))
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.detailErrorMessage = error.response.data.detail;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    removePreAlert() {
      this.loading = true;

      this.deletePreAlert(this.chosenPreAlert)
        .then(() => {
          this.setPreAlertData();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
          this.deletePreAlertDialog = false;
        });
    },

    setPreAlertData() {
      this.loading = true;

      this.fetchPreAlerts()
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.setPreAlertData();
  },
});
