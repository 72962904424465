










































































































import { PreAlert } from '@/models/preAlert';
import dateFormat from '@/utils/dateFormat';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    filterPreAlertType: {
      type: [] as PropType<PreAlert[]>,
    },
  },
  methods: {
    dateFormat,

    openDialog(preAlert: PreAlert) {
      this.$emit('openDialog', preAlert, true);
    },

    selectedPreAlert(preAlert: PreAlert) {
      this.$emit('selectedPreAlert', preAlert);
    },

    openPreAlertDetailDialog(preAlert: PreAlert) {
      this.$emit('openPreAlertDetailDialog', preAlert);
    },

    updatePreAlertFullfilled(preAlert: PreAlert) {
      this.$emit('updatePreAlertFullfilled', preAlert);
    },
  },
});
